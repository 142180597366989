import React from 'react';
import './Contact.css'
import './Contactmobile.css'
import {Intro, IntroMobile} from "../utils/Intro";
import { Button } from "antd";


function Contact() {
    return (
        <div className='contacts'>
            <Intro
                title={"Contact"}
                body={"Slide into our DMs to find out how to join the TSA family! We can’t wait to hear from and meet you all."}
                imgURL={"contact.JPG"}
            />
            <div className='descriptions'>
                <div className='imgtext'>
                    <img src='./table.JPG' className='mainphotos'></img>
                    <div className='textbox'>
                        <h2>Tabling</h2>
                        <p>
                            Our tabling hours are from 11:00 AM to 2:00 PM Monday to Friday on Sproul! 
                            You are more than welcome to swing by and say hi. 
                        </p>
                    </div>
                </div>
                <div className='imgtext'>
                    <div className='textbox'>
                        <h2>Social Media</h2>
                        <p>
                            Follow us on social media to hear all about our events and get know our lovely officers better.
                            Don’t miss out on all our exclusive content!
                        </p>
                        <div className='icons'>
                            <a href="https://www.facebook.com/ucberkeleytsa">
                                <img src='./facebook.png' className='icon'></img>
                            </a>
                            <a href="https://www.instagram.com/berkeleytsa">
                                <img src='./instagram.png' className='icon'></img>
                            </a>
                            <a href="https://www.youtube.com/user/TSABERKELEY">
                                <img src='./youtube.png' className='icon'></img>
                            </a>
                            <a href="mailto:tsa.berkeley@gmail.com">
                                <img src='./email.png' className='icon'></img>
                            </a>
                        </div>
                    </div>
                    <img src='./socialmedia.JPG' className='mainphotos'></img>
                </div>
                <div className='imgtext'>
                    <img src='./sponsors.JPG' className='mainphotos'></img>
                    <div className='textbox'>
                        <h2>Sponsors</h2>
                        <p>Without our sponsors, TSA will not be able to bring all these fun events to you! A big thank you to our sponsors!</p>
                        <a href="mailto:tsa.berkeley@gmail.com">
                            <Button 
                                type="primary"
                                className="contactus"
                                size='large'
                            >Contact Us</Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ContactMobile() {
    return (
        <div className='contactsmobile'>
            <IntroMobile
                title={"Contact"}
                body={"Slide into our DMs to find out how to join the TSA family! We can’t wait to hear from and meet you all."}
                imgURL={"contact.JPG"}
            />
            <div className='imgtext'>
                <div>
                    <img src='./table.JPG' ></img>
                    <h2>Tabling</h2>
                    <p>
                        Our tabling hours are from 11:00 AM to 2:00 PM Monday to Friday on Sproul! 
                        You are more than welcome to swing by and say hi. 
                    </p>
                </div>
                <div>
                    <img src='./socialmedia.JPG'></img>
                    <h2>Social Media</h2>
                    <p>
                        Follow us on social media to hear all about our events and get know our lovely officers better. 
                        Don’t miss out on all our exclusive content!
                    </p>
                    <div className='icons'>
                        <a href="https://www.facebook.com/ucberkeleytsa">
                            <img src='./facebook.png' className='icon'></img>
                        </a>
                        <a href="https://www.instagram.com/berkeleytsa">
                            <img src='./instagram.png' className='icon'></img>
                        </a>
                        <a href="https://www.youtube.com/user/TSABERKELEY">
                            <img src='./youtube.png' className='icon'></img>
                        </a>
                        <a href="mailto:tsa.berkeley@gmail.com">
                            <img src='./email.png' className='icon'></img>
                        </a>
                    </div>
                </div>
                <div>
                    <img src='./sponsors.JPG' ></img>
                    <h2>Sponsors</h2>
                    <p>
                    Without our sponsors, TSA will not be able to bring all these fun events to you! 
                    A big thank you to our sponsors!
                    </p>
                    <a href="mailto:tsa.berkeley@gmail.com">
                        <Button 
                            type="primary"
                            className="contactus"
                            size='large'
                        >Contact Us</Button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export {
    Contact,
    ContactMobile
};
