import React, {Component} from 'react';
import './EventHighlights5.css';
import './EventHighlights5-mobile.css';

function EventsHighlights5({mobile}) {
    if (mobile === true) {
        return (
            <div className="event5-mobile">
                <img src = "basketball11.JPG" className='img'></img>
                <img src = "basketball22.JPG" className='img'></img>
                <div className="event-title">
                    <p>Basketball Tournament</p>
                </div>
                <div className="event-detail">
                    <p>Showcase your basketball skills and have fun on the court with UC Davis TSA, UC Davis PERMIAS, and NSTC!</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="event5">
                <div className="event-highlights5">
                    <div className="event-title">
                        <p>Basketball Tournament</p>
                    </div>
                    <div className="event-detail">
                        <p>Showcase your basketball skills and have fun on the court with UC Davis TSA, UC Davis PERMIAS, and NSTC!</p>
                    </div>
                    <img src = "basketball11.JPG" className='img2' ></img>
                </div>
                <div className = "img1">
                    <img src = "basketball22.JPG"></img>
                </div>
            </div>
        )
    }
}

export default EventsHighlights5;