import React, { Compnent } from "react";
import "./homePage.css";
import "./homePage-mobile.css"

function HomeMobile() {
  return (
    <div className="home-intro-mobile">
      <div className="title">
        <img src="home.png" className="groupPic"></img>
      </div>

      <div className="morePhoto">
        <img src="home1.1.JPG" className="groupPic"></img>
        <img src="home2.1.JPG" className="groupPic"></img>
      </div>

      <div className="blockPhoto">
        <div className="introTextPhoto">
          <div className= "vertical"></div>
          <p>
            The Taiwanese Student Association (TSA) at UC Berkeley is non-profit,
            non-political student organization that focuses on enriching the
            college experience of anyone who shares common interest in Taiwanese
            culture. Our goal is mainly to represent the Taiwanese community and
            strengthen its voice and beyond the campus. TSA is more than a club. It’s a family, a place where long-lasting friendships form and a home away from home!
          </p>
        </div>
        <img src="home3.1.JPG" className="groupPic"></img>
      </div>
    
      <div className="largeGroupPhoto">
        <img src="home-bg1.JPG"></img>
      </div>

      <div className="shortSummary">
        <p>
          How does TSA relate to you? We hope to promote the awareness of
          Taiwanese culture to the entire student body as well as to build
          lasting friendships. TSA means more than a club. It is a place, a
          family, where everyone belongs to when you are away from home!
        </p>
      </div>
    </div>
  );
}

function Home() {
  return (
    <div className="home-intro">
      {/* <div className="title">
        <h1>Taiwanese Student Association at UC Berkeley</h1>
        <img src="home-first.png" className="groupPic"></img>
      </div> */}
      <div className="title">
        <img src="home.png" className="groupPic"></img>
      </div>

      <div className="morePhoto">
        <img src="home1.1.JPG" className="groupPic"></img>
        <img src="home2.1.JPG" classNmae="groupPic"></img>
      </div>

      <div className="introTextPhoto">
        <div class= "vertical"></div>
          <p>
            The Taiwanese Student Association (TSA) at UC Berkeley is non-profit,
            non-political student organization that focuses on enriching the
            college experience of anyone who shares common interest in Taiwanese
            culture. Our goal is mainly to represent the Taiwanese community and
            strengthen its voice and beyond the campus. TSA is more than a club. It’s a family, a place where long-lasting friendships form and a home away from home!
          </p>
          <img src="home3.1.JPG" className="groupPic"></img>
        </div>
        
        

      <div className="largeGroupPhoto">
        <img src="home-bg1.JPG"></img>
      </div>

      <div className="shortSummary">
        <p>
          How does TSA relate to you? We hope to promote the awareness of
          Taiwanese culture to the entire student body as well as to build
          lasting friendships. TSA means more than a club. It is a place, a
          family, where everyone belongs to when you are away from home!
        </p>
      </div>

    </div>
  );
}

export {
  Home,
  HomeMobile
};
