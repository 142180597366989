import "./App.css";
import firebase from "./firebase.js";
import { NavBar, Hamburger} from "./Header/Header";
import { Footer, MobileFooter } from "./Header/Footer";
import { Events, MobileEvents} from "./Events/Events";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { TSAi, TSAiMobile } from "./TSAI/TSAi";
import { Officer, OfficerMobile} from "./Officer/Officer";
import { Join, JoinMobile} from "./Join/Join";
import { Contact, ContactMobile} from "./Contact/Contact";
import { Resource, ResourceMobile } from "./Resource/Resource";
import { Home, HomeMobile} from "./Home/homePage";
import { submitted, submittedMobile } from "./internapp/submittedForm";
import { Sponsors, SponsorsMobile} from './Sponsors/Sponsors'
import { Member, MemberMobile } from "./Member/Member";
import { InternApp, InternAppMobile } from "./internapp/InternForm"
import { closed, closedMobile } from "./internapp/closedForm"
import { MemberSubmitted, MemberSubmittedMobile } from './Member/memberSubmitted'
import React, {useState, useEffect} from "react";

function App() {
  const [width, _] = React.useState(window.innerWidth);
  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    (async () => {
        let allStatuses = []
        const statusRef = firebase.firestore().collection("AppStatus");
        const snapshot = await statusRef.get();
        snapshot.forEach(doc => {
          allStatuses.push({id: doc.id, ...doc.data()});
        });
        setStatuses(allStatuses)
        console.log(allStatuses);
    })()
  }, [])

  if (width <= 600) {
    return (
      <div className="App">
        <Router>
          <Hamburger></Hamburger>
          <Route exact path="/" component={HomeMobile} />
          <Route exact path="/events" component={MobileEvents} />
          <Route exact path="/tsai" component={TSAiMobile} />
          <Route exact path="/officers" component={OfficerMobile} />
          <Route exact path="/join" component={JoinMobile} />
          <Route exact path="/sponsor" component={SponsorsMobile} />
          <Route exact path='/internapp' component={statuses[0] && statuses[0].isOpen ? InternAppMobile : closedMobile} />
          {/* <Route exact path='/internapp' component={closedMobile} /> */}
          <Route exact path='/internsubmitted' component={submittedMobile} />
          <Route exact path="/contact" component={ContactMobile} />
          <Route exact path="/resources" component={ResourceMobile} />
          <Route exact path='/members' component={MemberMobile} />
          {/* <Route exact path='/membersubmitted' component={MemberSubmittedMobile} /> */}
          <MobileFooter></MobileFooter>
        </Router>
      </div>
    )
  } else {
    return (
      <div className="App">
        <Router>
          <NavBar></NavBar>
          <Route exact path="/" component={Home} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/tsai" component={TSAi} />
          <Route exact path="/officers" component={Officer} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/sponsor" component={Sponsors} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/resources" component={Resource} />
          <Route exact path='/internapp' component={statuses[0] && statuses[0].isOpen ? InternApp : closed} />
          {/* <Route exact path='/internapp' component={closed} /> */}
          <Route exact path='/internsubmitted' component={submitted} />
          <Route exact path='/members' component={Member} />
          {/* <Route exact path='/membersubmitted' component={MemberSubmitted} /> */}
          <Footer></Footer>
        </Router>
      </div>
    )
  }
}

export default App;
