import firebase from "../firebase";
import React, {useState, useEffect} from "react";
import "./Events.css";
import "./Events-mobile.css";
import EventHighlights1 from './EventHighlights1';
import EventHighlights2 from './EventHighlights2';
import EventHighlights3 from './EventHighlights3';
import EventHighlights4 from './EventHighlights4';
import EventHighlights5 from './EventHighlights5';
import {Modal, Row, Col} from "antd";
import {Intro, IntroMobile} from "../utils/Intro";

function MobileEvents() {
    const [events, setEvents] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEvent, setModalEvent] = useState({});

    useEffect(() => {
        (async () => {
            let allEvents = []
            const eventsRef = firebase.firestore().collection("events");
            const snapshot = await eventsRef.get();
            snapshot.forEach(doc => {
                allEvents.push({id: doc.id, ...doc.data()});
            });
            setEvents(allEvents.sort((firstEvent,secondEvent) => firstEvent.order - secondEvent.order));
            console.log(allEvents);
        })()
    }, [])

    function handleCardSelect(event) {
        setModalVisible(true);
        setModalEvent(event);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }
    return (
        <div className="events-mobile">
            <IntroMobile
                title={"Events"}
                imgURL={"event.JPG"}
                body={"Here at TSA, we host events every couple of weeks, usually on Fridays. TSA’s events bring together people from different identities, interests, and passions while aiming to highlight and celebrate Taiwanese culture through various campus events. Every semester we host KCON, our karaoke competition, among other smaller events such as Boba Factory, Lantern Festival, scavenger hunts, or even a Taiwanese language class! Our events are always so much fun and such a nice way to bond with fellow Taiwanese bears!"}
            />
            <div className="past-events">
                <div className = "intro">
                    <p><strong>Past Events</strong></p>
                </div>
                <div className= "past-events-lists">
                    <Row gutter={[24, 32]}>
                        {
                            events.map((event, index) => {
                                return (
                                    <Col span={24}>
                                        <EventCard
                                            event={event}
                                            index={index}
                                            handleCardSelect={handleCardSelect}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>

            </div>
            <EventModal
                modalEvent={modalEvent}
                modalVisible={modalVisible}
                handleModalCancel={handleModalCancel}
            />
            <div className = "highlights">
                <div className = "title">
                    <h2>Event Highlights</h2>
                </div>
                <EventHighlights5 mobile = {true}/>
                <EventHighlights1 mobile = {true}/>
                <EventHighlights2 mobile = {true}/>
                <EventHighlights3 mobile = {true}/>
                <EventHighlights4 mobile = {true}/>
            </div>
        </div>
    )
}

function Events() {
    const [events, setEvents] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEvent, setModalEvent] = useState({});

    useEffect(() => {
        (async () => {
            let allEvents = []
            const eventsRef = firebase.firestore().collection("events");
            const snapshot = await eventsRef.get();
            snapshot.forEach(doc => {
                allEvents.push({id: doc.id, ...doc.data()});
            });
            setEvents(allEvents.sort((firstEvent,secondEvent) => firstEvent.order - secondEvent.order));
            console.log(allEvents);
        })()
    }, [])

    function handleCardSelect(event) {
        setModalVisible(true);
        setModalEvent(event);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }
    return (
        <div className="events">
            <Intro
                title={"Events"}
                body={"Here at TSA, we host events every couple of weeks, usually on Fridays. TSA’s events bring together people from different identities, interests, and passions while aiming to highlight and celebrate Taiwanese culture through various campus events. Every semester we host KCON, our karaoke competition, among other smaller events such as Boba Factory, Lantern Festival, scavenger hunts, or even a Taiwanese language class! Our events are always so much fun and such a nice way to bond with fellow Taiwanese bears!"}
                imgURL={"event.JPG"}
            />
            <div className="past-events">

                <div className = "intro">
                    <p>Past Events</p>
                </div>
                <div className= "past-events-lists">
                    <Row gutter={[24, 32]}>
                        {
                            events.map((event, index) => {
                                return (
                                    <Col span={8}>
                                        <EventCard
                                            event={event}
                                            index={index}
                                            handleCardSelect={handleCardSelect}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>

            </div>

            <EventModal
                modalEvent={modalEvent}
                modalVisible={modalVisible}
                handleModalCancel={handleModalCancel}
            />
            
            <div className = "highlights">
                <div className = "title">
                    <h2>Event Highlights</h2>
                </div>
                <EventHighlights5 />
                <EventHighlights1 />
                <EventHighlights2 />
                <EventHighlights3 />
                <EventHighlights4 />
            </div>
        </div>
    )
}

function EventModal({modalVisible, handleModalCancel, modalEvent}) {
    return (
        <Modal
            visible={modalVisible}
            width={"50%"}
            className={'event-modal'}
            center
            footer={null}
            onCancel={handleModalCancel}
        >
            <div className={"modal"}>
                <div className={"modal-image"} >
                    <img src={modalEvent.imageURL}/>
                </div>
                <div className={"modal-body"}>
                    <div className="modal-event-name">
                        <h1>{modalEvent.name}</h1>
                    </div>
                    <div className="modal-event-time">
                        <h3>
                            <b>Time: </b>
                            {modalEvent.date}
                        </h3>
                    </div>
                    <div className="modal-event-location">
                        <h3>
                            <b>Location: </b>
                            {modalEvent.location}
                        </h3>
                    </div>
                    <div className="modal-event-description">
                        <p>
                            {modalEvent.description}
                        </p>
                    </div>
                    <a href={modalEvent.eventPageURL}>
                        <div className={"modal-event-learn-more"}>
                            <h1>Learn More</h1>
                            <img src={"arrow-right.png"} />
                        </div>
                    </a>
                </div>
            </div>
        </Modal>
    )
}

function EventCard({event, index, handleCardSelect}) {
    return (
        <div
            className="event-card"
            key={index}
            onClick={() => handleCardSelect(event)}
        >
            <img src={event.imageURL}/>
        </div>
    )
}

export {
    Events,
    MobileEvents
}