import React, {Component} from 'react';
import './EventHighlights3.css';
import './EventHighlights3-mobile.css';


function EventsHighlights3({mobile}) {
    if (mobile === true) {
        return (
            <div className="event3-mobile">
                <div className='imgs'>
                    <img src = "meet1.JPG" className = "img1"></img>
                    <img src = "meet2.JPG" className = "img2"></img>
                </div>
                <div className="event-highlights3">
                    <div className="event-title">
                        <p>Meet the Bears</p>
                    </div>
                    <div className="event-detail">
                        <p>Feeling lost and don't know where to get resources at Cal? Come meet the officer core of TSA and learn some facts about Cal!</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="event3">
                <div className = "img1">
                    <img src = "meet1.JPG" className = "img1"></img>
                </div>
                <div className="event-highlights3">
                    <div className="event-title">
                        <p>Meet the Bears</p>
                    </div>
                    <div className="event-detail">
                        <p>Feeling lost and don't know where to get resources at Cal? Come meet the officer core of TSA and learn some facts about Cal!</p>
                    </div>
                    <img src = "meet2.JPG" className = "img2"></img>
                </div>
            </div>
        )
    }
}

export default EventsHighlights3;