import React from 'react';
import './Member.css'
import './Member-mobile.css'
import {Intro, IntroMobile} from "../utils/Intro";
import FileUpload from './src/components/FileUpload';
import firebase from "../firebase";
import { useHistory } from "react-router-dom";
import {Form, Row, Col, Input, Button, Select} from "antd";
import {addToMemberlist, addToMailinglist} from '../googlesheets';

function MemberMobile() {
    const history = useHistory();
    const onFinish = (values) => {
        if (values['Pronoun'] === undefined) {
            values['Pronoun'] = 'Not Stated'
        }
        addToMemberlist(values);
        addToMailinglist(values);
        history.push('/membersubmitted')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='membermobile'>
            <IntroMobile
                title={"Members"}
                body={"Members are an essential part of TSA. As a member, you will receive various opportunities to meet other people within the community and interact with Taiwanese culture. While all students are welcome to engage with TSA, members receive special discounts and privileges at our events, such as getting a free boba at our semesterly Boba Social event."}
                imgURL={"member.png"}
            />
            <div className='intro1'>
                <h2>
                    01. Amity H/
                </h2>
                <p>
                    TSA is like my second home. It helped me form a tight knit group of friends when I first came to Cal 
                    and allowed me to realize a lot of my potential. I learned to work well in a team and found a family 
                    to hang out with and study together outside of official TSA activities too.
                </p>
            </div>
            <div className='intro1'>
                <h2>
                    02. Eric Y.
                </h2>
                <p>
                    TSA was always more than just another club on campus to me. It was, as clichéd as it may sound, 
                    truly a home away from home. Four years of college brings with it lots of ups and downs and 
                    unpredictability, yet the one thing you could always count on was TSA having your back. 
                    Feeling down after a botched midterm exam? Ask the group chat and someone will be immediately 
                    down to grab boba with you. Want to do something exciting for your birthday? Don’t worry, someone 
                    has probably already planned something. Perhaps that’s why even after graduation, the people you end 
                    up hanging out with after work will still end up being the TSA crew.
                </p>
            </div>
            <div className='intro1'>
                <h2>
                    03. Frances Y.
                </h2>
                <p>
                    I joined TSA in my freshman year and it changed the course of my college career. 
                    The people I’ve met and the memories I’ve made along the way have helped me grow tremendously. 
                    I don’t know what I would’ve done without the late night popcorn chicken runs, random Karaoke sessions, 
                    and spontaneous spring break trips abroad. As cliche as it sounds, TSA is my family away from home where 
                    I built relationships that have lasted well beyond college.
                </p>
            </div>
            <img src='./membermobile.jpg'></img>
            <div className='MemberForm'>
                <h2>
                    Become a Member
                </h2>
                <Form
                    name="MemberForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Col>
                        <Form.Item 
                            label='First Name:' 
                            name = 'first_name' 
                            rules = {[{required:true, message: 'Please input your first name!'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Last Name:' 
                            name = 'last_name' 
                            rules = {[{required:true, message: 'Please input your last name!'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Email:' 
                            name = 'Email' 
                            rules = {[{required:true, message: 'Please input your email!'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Graduation Year:' 
                            name = 'Graduation' 
                            rules = {[{required:true, message: 'Please input your graduation year!'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Major:' 
                            name = 'Major' 
                            rules = {[{required:true, message: 'Please input your major!'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Pronouns:' 
                            name = 'Pronoun' 
                            >
                            <Input  placeholder="N/A"/>
                        </Form.Item>
                    </Col>
                    <p>
                        Here at TSA, we have two kinds of memberships. The only difference between the two 
                        is simply the time period and the price. No matter which plan you choose, you will
                        receive the same benefits! Your choices are listed below:
                        <br/>
                        &nbsp;&nbsp;1. Lifetime Membership: $25<br/>
                        &nbsp;&nbsp;2. One Semester Membership: $10<br/> 
                    </p>
                    <Form.Item 
                        label="Type of Member: " 
                        className='position-select' 
                        name='Type' 
                        initialValue = 'Please select type of member' 
                        rules = {[{required:true}]}
                        >
                        <Select>
                            <Select.Option value="Lifetime">Life Time ($25)</Select.Option>
                            <Select.Option value="Semester">Semester ($10)</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 10, span: 50 }}>
                        <Button className = 'submit' type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function Member() {
    const history = useHistory();
    const onFinish = (values) => {
        console.log(values)
        if (values['Pronoun'] === undefined) {
            values['Pronoun'] = 'Not Stated'
        }
        addToMemberlist(values);
        addToMailinglist(values);
        history.push('/membersubmitted')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            <Intro
                title={"Members"}
                body={"Members are an essential part of TSA. As a member, you will receive various opportunities to meet other people within the community and interact with Taiwanese culture. While all students are welcome to engage with TSA, members receive special discounts and privileges at our events, such as getting a free boba at our semesterly Boba Social event."}
                imgURL={"member.png"}
            />
            <div className='MemberIntros'>
                <div className='intro1'>
                    <h2>
                        01. <br />
                        Amity H.
                    </h2>
                    <p>
                        TSA is like my second home. It helped me form a tight knit group of friends when I first came to Cal 
                        and allowed me to realize a lot of my potential. I learned to work well in a team and found a family 
                        to hang out with and study together outside of official TSA activities too.
                    </p>
                </div>
                <div className='intro1'>
                    <h2>
                        02. <br />
                        Eric Y.
                    </h2>
                    <p>
                        TSA was always more than just another club on campus to me. It was, as clichéd as it may sound, 
                        truly a home away from home. Four years of college brings with it lots of ups and downs and 
                        unpredictability, yet the one thing you could always count on was TSA having your back. 
                        Feeling down after a botched midterm exam? Ask the group chat and someone will be immediately 
                        down to grab boba with you. Want to do something exciting for your birthday? Don’t worry, someone 
                        has probably already planned something. Perhaps that’s why even after graduation, the people you end 
                        up hanging out with after work will still end up being the TSA crew.
                    </p>
                </div>
                <div className='intro1'>
                    <h2>
                        03. <br />
                        Frances Y.
                    </h2>
                    <p>
                        I joined TSA in my freshman year and it changed the course of my college career. 
                        The people I’ve met and the memories I’ve made along the way have helped me grow tremendously. 
                        I don’t know what I would’ve done without the late night popcorn chicken runs, random Karaoke sessions, 
                        and spontaneous spring break trips abroad. As cliche as it sounds, TSA is my family away from home where 
                        I built relationships that have lasted well beyond college.
                    </p>
                </div>
            </div>
            <div className='MemberForm'>
                <h2>
                    Become a Member
                </h2>
                <Form
                    name="MemberForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div className='member-rows'>
                        <Row>
                            <Col span={12}>
                                <Form.Item 
                                    label='First Name:' 
                                    name = 'first_name' 
                                    rules = {[{required:true, message: 'Please input your first name!'}]}>
                                    <Input  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label='Last Name:' 
                                    name = 'last_name' 
                                    rules = {[{required:true, message: 'Please input your last name!'}]}>
                                    <Input  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item 
                                    label='Email:' 
                                    name = 'Email' 
                                    rules = {[{required:true, message: 'Please input your email!'}]}>
                                    <Input  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label='Graduation Year:' 
                                    name = 'Graduation' 
                                    rules = {[{required:true, message: 'Please input your graduation year!'}]}>
                                    <Input  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item 
                                    label='Major:' 
                                    name = 'Major' 
                                    rules = {[{required:true, message: 'Please input your major!'}]}>
                                    <Input  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label='Pronouns:' 
                                    name = 'Pronoun' 
                                    >
                                    <Input  placeholder="N/A" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='type'>
                            <p>
                                Here at TSA, we have two kinds of memberships. The only difference between the two 
                                is simply the time period and the price. No matter which plan you choose, you will
                                receive the same benefits! Your choices are listed below:
                                <br/>
                                &nbsp;&nbsp;1. Lifetime Membership: $25<br/>
                                &nbsp;&nbsp;2. One Semester Membership: $10<br/> 
                            </p>
                            <Form.Item 
                                label="Type of Member: " 
                                className='position-select' 
                                name='Type' 
                                initialValue = 'Please select type of member' 
                                rules = {[{required:true}]}
                                >
                                <Select>
                                    <Select.Option value="Lifetime">Life Time ($25)</Select.Option>
                                    <Select.Option value="Semester">Semester ($10)</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='container mt-4'>
                            <h2>
                                Upload Screenshot of Payment
                            </h2>

                            <FileUpload />
                        </div> 
                        <Form.Item wrapperCol={{ span: 16 }}>
                            <Button className = 'submit' type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div> 
    )
}

export {
    Member,
    MemberMobile
};
