import './submittedForm.css'
import './submittedForm-mobile.css'

function submitted() {
    return (
        <div className="internsubmitted">
            <h2>You are one step closer to joining the TSA Family!</h2>
            <p>
                Thank you for applying for TSA's intern. We will contact you 
                with your interview time slot, if you are selected for the interview 
                process. 
            </p>
        </div>
    )
}

function submittedMobile() {
    return (
        <div className="internsubmitted-mobile">
            <h2>You are one step closer to joining the TSA Family!</h2>
            <p>
                Thank you for applying for TSA's intern. We will contact you 
                with your interview time slot, if you are selected for the interview 
                process. 
            </p>
        </div>
    )
}

export {
    submitted, 
    submittedMobile
};