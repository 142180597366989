import React from 'react';
import './Resource.css'
import './Resource-mobile.css'
import {Intro, IntroMobile} from "../utils/Intro";
import {Link} from "react-router-dom";

function Resource() {
    return (
        <div>
            <Intro
                title={"Resources"}
                body={"To further enrich our community, TSA has established a resource bank where everyone is welcome to share and receive resources related to academics, student organizations, professional development, and more. You can obtain these resources by clicking on the links below. We hope that this platform will maximize the value of our network and bring this community closer together."}
                imgURL={"resources_main.JPG"}
            />
            <div className='resources'>
                <div className='eachlink'>
                    <a href="https://docs.google.com/presentation/d/19blTMQeh6ZH1GNNb1ORWOm_oWoJFyhB0desYD313jEQ/edit?usp=sharing" className='resourcelink'>2022 Meet the Bears Slides</a>
                    <div className='horizontal'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2sC5ugqEaXY_5lQDFx9CWemAol9fPLdA" className='resourcelink'>TSA Berkeley Food Guide</a>
                    <div className='horizontal1'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/ucberkeleytsa" className='resourcelink'> Berkeley TSA Facebook Group</a>
                    <div className='horizontal2'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://nightsafety.berkeley.edu/" className='resourcelink'> Berkeley Safety Guide</a>
                    <div className='horizontallast'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/groups/203114973093623" className='resourcelink'>Bay Area Taiwanese Student Association</a>
                    <div className='horizontal3'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/groups/266259930135554" className='resourcelink'>Free &amp; For Sale</a>
                    <div className='horizontal4'></div>
                </div>
                <div className='secret'>
                    <a href="https://www.youtube.com/watch?v=CQdW6NgXwS0" className='resourcelink'>Secret Meme</a>
                    <div className='horizontal4'></div>
                </div>
            </div>
        </div>
    )
}

function ResourceMobile() {
    return (
        <div>
            <IntroMobile
                title={"Resources"}
                body={"To further enrich our community, TSA has established a resource bank where everyone is welcome to share and receive resources related to academics, student organizations, professional development, and more. You can obtain these resources by clicking on the links below. We hope that this platform will maximize the value of our network and bring this community closer together."}
                imgURL={"resources.png"}
            />
            <div className='resourcesmobile'>
                <div className='eachlink'>
                    <a href="https://docs.google.com/presentation/d/1w50trBBIQ7bNiVnKkr3MpvZh4t4folrK5SQc5k5PuJ4/edit?usp=sharing" className='resourcelink'>2021 Meet the Bears Slides</a>
                    <div className='horizontal'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2sC5ugqEaXY_5lQDFx9CWemAol9fPLdA" className='resourcelink'>TSA Berkeley Food Guide</a>
                    <div className='horizontal1'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/ucberkeleytsa" className='resourcelink'> Berkeley TSA Facebook Group</a>
                    <div className='horizontal2'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://nightsafety.berkeley.edu/" className='resourcelink'> Berkeley Safety Guide</a>
                    <div className='horizontallast'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/groups/203114973093623" className='resourcelink'>Bay Area Taiwanese Student</a>
                    <div className='horizontal3'></div>
                    <a href="https://www.facebook.com/groups/203114973093623" className='resourcelink'>Association</a>
                    <div className='horizontalbottom'></div>
                </div>
                <div className='eachlink'>
                    <a href="https://www.facebook.com/groups/266259930135554" className='resourcelink'>Free &amp; For Sale</a>
                    <div className='horizontal4'></div>
                </div>
                <div className='secret'>
                    <a href="https://www.youtube.com/watch?v=CQdW6NgXwS0" className='resourcelink'>Secret Meme</a>
                    <div className='horizontal4'></div>
                </div>
            </div>
        </div>
    )
}

export {
    Resource,
    ResourceMobile
};