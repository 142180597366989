import React, {Component} from 'react';
import './Intro.css';
import './Intro-mobile.css';

function IntroMobile({title, body, imgURL}) {
    return (
        <div className="page-intro-mobile">
            <div className="description">
                <div className="title">
                    <p>{title}</p>
                </div>
                <div className="intro-image">
                    <img src={imgURL}></img>
                </div>
                <div className = 'text'>
                    <div class= "vertical"></div>
                    <div className="body">
                        <p>{body}</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

function Intro({title, body, imgURL}) {
    return (
        <div className="page-intro">
            <div className="description">
                <div className="title">
                    <p>{title}</p>
                </div>
                <div className='text'>
                    <div class= "vertical"></div>
                    <div className="body">
                        <p>{body}</p>
                    </div>
                </div>
            </div>
            <div className="intro-image">
                <img src={imgURL}></img>
            </div>
        </div>
    )
}

export {
    Intro, 
    IntroMobile
};