import React, {Component} from 'react';
import './EventHighlights1.css';
import './EventHighlights1-mobile.css';

function EventsHighlights1({mobile}) {
    if (mobile === true) {
        return (
            <div className="event1-mobile">
                <img src = "bobasocial1.JPG" className='img'></img>
                <img src = "bobasocial2.JPG" className='img'></img>
                <div className="event-title">
                    <p>Boba Social</p>
                </div>
                <div className="event-detail">
                    <p>Getting some of that cheap boba, while meeting up with some old friends and getting to know some new people!</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="event1">
                <div className = "img1">
                    <img src = "bobasocial1.JPG"></img>
                </div>
                <div className="event-highlights1">
                    <div className="event-title">
                        <p>Boba Social</p>
                    </div>
                    <div className="event-detail">
                        <p>Getting some of that cheap boba, while meeting up with some old friends and getting to know some new people!</p>
                    </div>
                    <img src = "bobasocial2.JPG" className='img2' ></img>
                </div>
            </div>
        )
    }
}

export default EventsHighlights1;