import "./closedForm.css"
import "./closedForm-mobile.css"


function closed() {
    return (
        <div className="closedForm">
            <p>
                The application window has closed for this semester. Please be on the look out for our next cycle!
                In the mean time you can visit our social media for the latest news and events about TSA!
                Hope to see you at the next event. 
            </p>
        </div> 
    )
}

function closedMobile() {
    return (
        <div className="closedForm-mobile">
            <p>
                The application window has closed for this semester. Please be on the look out for our next cycle!
                In the mean time you can visit our social media for the latest news and events about TSA!
                Hope to see you at the next event. 
            </p>
        </div> 
    )
}

export {
    closed,
    closedMobile
}