import React from 'react';
import './TSAi.css'
import './TSAi-mobile.css'
import {Intro, IntroMobile} from "../utils/Intro";
import {tsaitextheader, tsaitext1, tsaitext2, tsailink} from "../text/tsai.json"

function TSAi() {
    return (
        <div className='tsai'>
            <Intro
                title={"TSAi"}
                body={tsaitextheader}
                imgURL={"tsai_main.JPG"}
            />
            <div className='tsaicontent1'>
                <img src='tsai2.1.JPG' className='img1'></img>
                <p>{tsaitext1}</p>
            </div>
            <div className='tsaicontent2'>
                <p>{tsaitext2}</p>
                <img src='tsai3.1.JPG'></img>
            </div>
            <div className='joining'>
                <a href={tsailink} className='text'>JOIN OUR TSAi FAMILY</a>
                <a href={tsailink} className='arrow-wrapped'>
                    <img src='./arrow-right.png' className='arrow'></img>
                </a>
            </div>
        </div>
    )
}

function TSAiMobile() {
    return (
        <div className='tsai-mobile'>
            <IntroMobile
                title={"TSAi"}
                body={tsaitextheader}
                imgURL={"tsai_main.JPG"}
            />
            <div className='tsaicontent1'>
                <img src='tsai2.1.JPG'></img>
                <p>{tsaitext1}</p>
            </div>
            <div className='tsaicontent1'>
                <img src='tsai3.1.JPG'></img>
                <p>{tsaitext2}</p>
            </div>
            <div className='joining'>
                <a href={tsailink} className='text'>JOIN OUR TSAi FAMILY</a>
                <a href={tsailink} className='arrow-wrapped'>
                    <img src='./arrow-right.png' className='arrow'></img>
                </a>
            </div>
        </div>
        
    )
}

export {
    TSAi,
    TSAiMobile
};