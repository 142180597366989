import React, {Component} from 'react';
import './EventHighlights4.css';
import './EventHighlights4-mobile.css';

function EventsHighlights4({mobile}) {
    if (mobile === true) {
        return (
            <div className="event4-mobile">
                <img src = "kcon2.JPG" className = "img"></img>
                <img src = "kcon1.JPG" className = "img"></img>
                <div className="event-highlights4">
                    <div className="event-title">
                        <p>KCON</p>
                    </div>
                    <div className="event-detail">
                        <p>Listen to some amazing performers!</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="event4">
                <div className="event-highlights4">
                    <div className="event-title">
                        <p>KCON</p>
                    </div>
                    <div className="event-detail">
                        <p>Listen to some amazing performers!</p>
                    </div>
                    <img src = "kcon2.JPG" className = "img2"></img>
                </div>
                <div className="img1">
                    <img src = "kcon1.JPG"></img>
                </div>
            </div>
        )
    }
}

export default EventsHighlights4;