import {Form, Checkbox, Row, Col, Input, Button, Radio, Select, notification} from "antd";
import './InternForm.css'
import './internForm-mobile.css'
import firebase from "../firebase";
import { addToInternInfo } from '../googlesheets';
import { useHistory } from "react-router-dom";


function InternApp() {
    const history = useHistory();
    const onFinish = (values) => {
        console.log('Success:', values);
        if (values['q6'] === undefined) {
            values['q6'] = 'Not Stated'
        }
        const eventsRef = firebase.firestore().collection("intern");
        eventsRef.add(values).then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        }).catch((error) => {
            console.error("Error adding document: ", error);
        });
        addToInternInfo(values);
        history.push('/internsubmitted')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='main'>
            <div className = 'titles'>
                <div className = 'text'>
                    <h1>Spring 2023 Intern Application</h1>
                </div>
                <div className='title'>
                    <div className="vertical"></div>
                    <div className="description">
                        <h2>Application due Sunday, February 5th 11:59PM (PST) </h2>
                        <p>Every piece of information provided on the application and during the interview must be as truthful as possible. You may be disqualified for inconsistent information.</p>
                    </div>
                   
                </div>
            </div>
            <Form
                name="InternApp"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                    <div className='rows'>
                        <Row justify='center'>
                            <Col span={12}>
                                <Form.Item 
                                    label='Full Name:' 
                                    name = 'name' 
                                    rules = {[{required:true, message: 'Please input your full name!'}]}>
                                    <Input placeholder="Ex: John Doe"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label='Phone Number:' 
                                    name = 'phone' 
                                    rules = {[{required:true, message: 'Please input your phone number!'}]}>
                                    <Input placeholder="(###) ###-####"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className='rows'>
                        <Row justify='center'>
                            <Col span={12}>
                                <Form.Item 
                                    label='Graduation Year:' 
                                    name = 'grad' 
                                    rules = {[{required:true, message: 'Please input your graduation year!'}]}>
                                    <Input placeholder="Ex: 2025" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label='Email:' 
                                    name = 'email' 
                                    rules = {[{required:true, message: 'Please input your email!'}]}>
                                    <Input placeholder="*Berkeley Email" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className='rows'>
                        <Row className='row'>
                            <Col span={12}>
                                <Form.Item 
                                    label='Major:' 
                                    name = 'major' 
                                    rules = {[{required:true, message: 'Please input your major!'}]}>
                                    <Input placeholder="Ex: Business + CS" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className='position'>
                        <p className='text'>Please indicate position(s) interested: </p>
                        <p className='descrip'><strong>Social</strong> - propose event ideas,  MC for events and KCON<br/>
                        {/* <strong>Treasurer</strong> - finding school and external sponsorships, handle all cash flow in the club<br/> */}
                        {/* <strong>Webmaster</strong> - maintain TSA website, make powerpoints for events<br/> */}
                        <strong>Public Relations</strong> - in charge of FB event pages, newsletters, and other social media accounts<br/>
                        <strong>Historian</strong> - (Please provide a few photos that you've taken) - event photos, officer photoshoot<br/>
                        <strong>Publicity</strong> - (Please provide a copy of work samples) - event flyer design</p>
                        <p className='last'> To learn more about the position, feel free to reach out to a TSA officer!</p>
                        <Form.Item 
                            label="First Choice Position: " 
                            className='position-select' 
                            name='first choice' 
                            rules = {[{required:true, message: 'Please at least choose one position!'}]}> 
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option> */}
                                {/* <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Second Choice Position: " className='position-select' name='second choice' initialValue = 'Any'>
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option> */}
                                {/* <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Third Choice Position: " className='position-select' name='third choice' initialValue = 'Any'>
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option> */}
                                {/* <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                                <Select.Option value="Any">Any</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='responses'>
                        <div className='one'>
                            <p className='text'>1. What (technical) skills do you have for the position(s) you apply for? What makes you suitable? (You may include past experiences from college or from high school.)</p>
                            <Col span={60}>
                                <Form.Item 
                                    name='q1' 
                                    rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>2. Rate yourself in each of the following categories from 1 to 10. Explain your rating. Responsibility, Efficiency, Socialness. </p>
                            <Col span={60}>
                                <Form.Item name='q2' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>3. Based on the events that you have attended so far, what suggestions do you have for improvement?</p>
                            <Col span={60}>
                                <Form.Item name='q3' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>4. If you were to host a new big tsa event, what event idea(s) would you propose?</p>
                            <Col span={60}>
                                <Form.Item name='q4' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>

                        <div className='one'>
                            <p className='text'>5. What is one thing you believe TSA can do better for our members?</p>
                            <Col span={60}>
                                <Form.Item name='q5' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>6. Are you involved in any other clubs or extracurricular activities? (i.e. sports, music, etc.)</p>
                            <Col span={60}>
                                <Form.Item name='q6' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>7. What do you hope to accomplish as an officer of TSA?</p>
                            <Col span={60}>
                                <Form.Item name='q7' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>8. For Historian and Publicity applicants, please provide a google drive link to your portfolio. (Remember to enable view permission for tsa.berkeley@gmail.com)</p>
                            <Col span={60}>
                                <Form.Item name='q8'>
                                    <Input.TextArea rows={1} placeholder="N/A"/>
                                </Form.Item>
                            </Col>
                        </div>
                    </div>
                    <div className='meeting'>
                        <p className='text'>Our officer meetings are going to be on Monday nights at 8:00 PM, and our events are usually on Friday evenings. Are you available during these times?</p>
                        <Form.Item name="meeting" className = 'bubble' rules = {[{required:true, message: 'Please tell us!'}]}>
                            <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='time'>
                        <div className='text'>
                            <p>Interviews will be held on WEDNESDAY, February 8th between 6:00 pm and 8:30 pm (PDT). Please check ALL the times you will be available. We will assign you a time slot and notify you via phone or email. </p>
                            <p>(If you are unavailable during these times, please let us know as soon as possible and we will make further arrangements for your interview.)</p>
                        </div>
                        <Form.Item name = 'interview' className='options' rules = {[{required:true, message: 'Please tell us!'}]}>
                            <Checkbox.Group >
                                    {/* <Row>
                                        <Col span={15}>
                                            <Checkbox value="5:30pm" style={{lineHeight: '32px',}}>5:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="5:45pm" style={{lineHeight: '32px',}}>5:45pm</Checkbox>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="6:00pm" style={{lineHeight: '32px',}}>6:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="6:15pm" style={{lineHeight: '32px',}}>6:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="6:30pm" style={{lineHeight: '32px',}}>6:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="6:45pm" style={{lineHeight: '32px',}}>6:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="7:00pm" style={{lineHeight: '32px',}}>7:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="7:15pm" style={{lineHeight: '32px',}}>7:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="7:30pm" style={{lineHeight: '32px',}}>7:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="7:45pm" style={{lineHeight: '32px',}}>7:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="8:00pm" style={{lineHeight: '32px',}}>8:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="8:15pm" style={{lineHeight: '32px',}}>8:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col span={15}>
                                            <Checkbox value="8:30pm" style={{lineHeight: '32px',}}>8:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="8:45pm" style={{lineHeight: '32px',}}>8:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="9:00pm" style={{lineHeight: '32px',}}>9:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="9:15pm" style={{lineHeight: '32px',}}>9:15pm</Checkbox>
                                        </Col>
                                    </Row> */}
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                        <Button className = 'submit' type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
            </Form>
        </div>
    )
}


function InternAppMobile() {
    const history = useHistory();
    const onFinish = (values) => {
        console.log('Success:', values);
        if (values['q6'] === undefined) {
            values['q6'] = 'Not Stated'
        }
        const eventsRef = firebase.firestore().collection("intern");
        eventsRef.add(values).then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        }).catch((error) => {
            console.error("Error adding document: ", error);
        });
        addToInternInfo(values);
        history.push('/internsubmitted')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='mainmobile'>
            <div className = 'titles'>
                <h1>Spring 2023 Intern Application</h1>
                <div className='title'>
                    <div className="vertical"></div>
                    <div className="description">
                        <h2>Application due Sunday, February 5th 11:59PM (PST) </h2>
                        <p>Every piece of information provided on the application and during the interview must be as truthful as possible. You may be disqualified for inconsistent information.</p>
                    </div>
                </div>
            </div>
            <Form
                name="InternApp"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                    <Col>
                        <Form.Item 
                            label='Full Name:' 
                            name = 'name' 
                            rules = {[{required:true, message: 'Please input your full name!'}]}>
                            <Input placeholder="Ex: John Doe"/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Phone Number:' 
                            name = 'phone' 
                            rules = {[{required:true, message: 'Please input your phone number!'}]}>
                            <Input placeholder="(###) ###-####"/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item 
                            label='Graduation Year:' 
                            name = 'grad' 
                            rules = {[{required:true, message: 'Please input your graduation year!'}]}>
                            <Input placeholder="Ex: 2025" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Email:' name = 'email' rules = {[{required:true, message: 'Please input your email!'}]}>
                            <Input placeholder="*Berkeley Email" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Major:' name = 'major' rules = {[{required:true, message: 'Please input your major!'}]}>
                            <Input placeholder="Ex: Business + CS" />
                        </Form.Item>
                    </Col>

                    <div className='position'>
                        <h2>Positions: </h2>
                        <p className='text'>Listed below are the positions available this semester: </p>
                        <p className='descrip'><strong>Social</strong> - propose event ideas,  MC for events and KCON<br/>
                        {/* <strong>Treasurer</strong> - finding school and external sponsorships, handle all cash flow in the club<br/>
                        <strong>Webmaster</strong> - maintain TSA website, make powerpoints for events<br/> */}
                        <strong>Public Relations</strong> - in charge of FB event pages, newsletters, and other social media accounts<br/>
                        <strong>Historian</strong> - (Please provide a few photos that you've taken) - event photos, officer photoshoot<br/>
                        <strong>Publicity</strong> - (Please provide a copy of work samples) - event flyer design</p>
                        <p className='last'> To learn more about the position, feel free to reach out to a TSA officer!</p>
                        <Form.Item 
                            label="First Choice Position: " 
                            className='position-select' 
                            name='first choice' 
                            rules = {[{required:true, message: 'Please at least choose one position!'}]}
                            > 
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option>
                                <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Second Choice Position: " 
                            className='position-select' 
                            name='second choice' 
                            initialValue = 'Any'
                            >
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option>
                                <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Third Choice Position: " 
                            className='position-select' 
                            name='third choice' 
                            initialValue = 'Any'
                            >
                            <Select>
                                <Select.Option value="Social">Social</Select.Option>
                                <Select.Option value="PR">PR</Select.Option>
                                <Select.Option value="Publicity">Publicity</Select.Option>
                                <Select.Option value="Historian">Historian</Select.Option>
                                {/* <Select.Option value="Webmaster">Webmaster</Select.Option>
                                <Select.Option value="Treasurer">Treasurer</Select.Option> */}
                                <Select.Option value="Any">Any</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='responses'>
                        <h2>Application questions</h2>
                        <div className='one'>
                            <p className='text'>1. What (technical) skills do you have for the position(s) you apply for? What makes you suitable? (You may include past experiences from college or from high school.)</p>
                            <Col>
                                <Form.Item name='q1' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>2. Rate yourself in each of the following categories from 1 to 10. Explain your rating. Responsibility, Efficiency, Socialness. </p>
                            <Col>
                                <Form.Item name='q2' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>3. Based on the events that you have attended so far, what suggestions do you have for improvement?</p>
                            <Col>
                                <Form.Item name='q3' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>4. If you were to host a new big tsa event, what event idea(s) would you propose?</p>
                            <Col>
                                <Form.Item name='q4' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>                        
                        <div className='one'>
                            <p className='text'>5. What is one thing you believe TSA can do better for our members?</p>
                            <Col>
                                <Form.Item name='q5' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>6. Are you involved in any other clubs or extracurricular activities? (i.e. sports, music, etc.)</p>
                            <Col>
                                <Form.Item name='q6' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>7. What do you hope to accomplish as an officer of TSA?</p>
                            <Col>
                                <Form.Item name='q7' rules = {[{required:true, message: 'This question is required'}]}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            </Col>
                        </div>
                        <div className='one'>
                            <p className='text'>8. For Historian and Publicity applicants, please provide a google drive link to your portfolio. (Remember to enable view permission for tsa.berkeley@gmail.com)</p>
                            <Col>
                                <Form.Item name='q8'>
                                    <Input.TextArea rows={1} placeholder="N/A"/>
                                </Form.Item>
                            </Col>
                        </div>
                    </div>
                    <h2>Scheduling Questions</h2>
                    <div className='meeting'>
                        <p className='text'>Our officer meetings are going to be on Monday nights at 8:00 PM, and our events are usually on Friday evenings. Are you available during these times?</p>
                        <Form.Item name="meeting" className = 'bubble' rules = {[{required:true}]}>
                            <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='time'>
                        <div className='text'>
                            <p className="p1">Interviews will be held on WEDNESDAY, February 8th between 6:00 pm and 8:30 pm (PDT). Please check ALL the times you will be available. We will assign you a time slot and notify you via phone or email. </p>
                            <p className="p2">(If you are unavailable during these times, please let us know as soon as possible and we will make further arrangements for your interview.)</p>
                        </div>
                        <Form.Item name = 'interview' className='options' rules = {[{required:true}]}>
                            <Checkbox.Group >
                                    {/* <Row>
                                        <Col span={15}>
                                            <Checkbox value="5:30pm" style={{lineHeight: '32px',}}>5:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="5:45pm" style={{lineHeight: '32px',}}>5:45pm</Checkbox>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="6:00pm" style={{lineHeight: '32px',}}>6:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="6:15pm" style={{lineHeight: '32px',}}>6:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="6:30pm" style={{lineHeight: '32px',}}>6:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="6:45pm" style={{lineHeight: '32px',}}>6:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="7:00pm" style={{lineHeight: '32px',}}>7:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="7:15pm" style={{lineHeight: '32px',}}>7:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="7:30pm" style={{lineHeight: '32px',}}>7:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="7:45pm" style={{lineHeight: '32px',}}>7:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="8:00pm" style={{lineHeight: '32px',}}>8:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="8:15pm" style={{lineHeight: '32px',}}>8:15pm</Checkbox>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col span={15}>
                                            <Checkbox value="8:30pm" style={{lineHeight: '32px',}}>8:30pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="8:45pm" style={{lineHeight: '32px',}}>8:45pm</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={15}>
                                            <Checkbox value="9:00pm" style={{lineHeight: '32px',}}>9:00pm</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="9:15pm" style={{lineHeight: '32px',}}>9:15pm</Checkbox>
                                        </Col>
                                    </Row> */}
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                        <Button className = 'submit' type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
            </Form>
        </div>
    )
}

export {
    InternApp,
    InternAppMobile
};