import React, {useState, useEffect} from "react";
import './Sponsors.css';
import './Sponsors-mobile.css';
import {Intro, IntroMobile} from "../utils/Intro";
import introphoto from "../assets/sponsors/sponsor.JPG"
import {Row, Col} from "antd";
import firebase from "../firebase";


function SponsorsMobile() {
    const sponsors = GetSponsor();

    return (
        <div className="sponsors-mobile">
            <IntroMobile
                title={"Sponsors"}
                body={"Without our sponsors, TSA will not be able to bring all these fun events to you! A big thank you to our sponsors!"}
                imgURL={introphoto}
            />
            <div className= "sponsor-list">
                <Row gutter={[30, 36]}>
                    {
                        sponsors.map((sponsor) => {
                            return (
                                <Col span={30}>
                                    <SponsorImages
                                    sponsor={sponsor}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

function Sponsors() {
    const sponsors = GetSponsor();

    return (
        <div>
            <div className="sponsors">
                <Intro
                    title={"Sponsors"}
                    body={"Without our sponsors, TSA will not be able to bring all these fun events to you! A big thank you to our sponsors!"}
                    imgURL={introphoto}
                />
            </div>
            <div className= "sponsor-list">
                <Row gutter={[30, 30]}>
                    {
                        sponsors.map((sponsor, index) => {
                            return (
                                <Col span={6}>
                                    <SponsorImages
                                    sponsor={sponsor}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
       
    )
}

function GetSponsor(){
    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {
        (async () => {
            let allSponsors = []
            const sponsorsRef = firebase.firestore().collection("sponsors");
            const snapshot = await sponsorsRef.get();
            snapshot.forEach(doc => {
                allSponsors.push({id: doc.id, ...doc.data()});
            });
            setSponsors(allSponsors);
            console.log(allSponsors);
        })()
    }, [])

    return sponsors
}


function SponsorImages({sponsor}) {
    if (sponsor.URL != ""){
        return (
            <div>
                <a href={sponsor.URL}>
                    <img src={sponsor.imageURL}/>
                </a>
            </div>
        )
    } else {
        return (
            <div>
                <img src={sponsor.imageURL}/>
            </div>
        )
    }
}

export {
    Sponsors,
    SponsorsMobile
};