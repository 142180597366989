import React from 'react';
import './Join.css'
import './Join-mobile.css'
import {Intro, IntroMobile} from "../utils/Intro";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import {joinheader, join1text, join2text} from "../text/join.json";


function Join() {
    const history = useHistory();
    function memberClicked() {
        console.log('clicked');
        history.push('./members');
    }

    function internClicked() {
        console.log('clicked');
        history.push('./internapp');
    }

    return (
        <div className='join'>
            <Intro
                title={"Join Us"}
                body={joinheader}
                imgURL={"joinus.JPG"}
            />
            <div className='joinblock1'>
                <img src='member.JPG' className='img1'></img>
                <div className='text1'>
                    <h2>Member</h2>
                    <p>{join1text}</p>
                    <Button 
                        type="primary"
                        className="member"
                        size='large'
                        // onClick={() => memberClicked()}
                    >
                        <a href="https://forms.gle/MicPUyXwv6tUUBVFA">Join</a>
                    </Button>
                </div>
                
                
            </div>
            {/* <div className='joinblock2'>
                <div className='text1'>
                    <h2>Intern</h2>
                    <p>{join2text}</p>
                    <Button 
                        type="primary"
                        className="member"
                        size='large'
                        onClick={() => internClicked()}
                    >Apply</Button>
                </div>
                <img src='join2.jpg' className='img1'></img>
            </div> */}
        </div> 
    )
}

function JoinMobile() {
    const history = useHistory();
    function memberClicked() {
        console.log('clicked');
        history.push('./members');
    }

    function internClicked() {
        console.log('clicked');
        history.push('./internapp');
    }

    return (
        <div class = 'joinmobile'>
            <IntroMobile
                title={"Join Us"}
                body={joinheader}
                imgURL={"joinus.JPG"}
            />
            <div className='block1'>
                <img src = 'member.JPG'></img>
                <h2>Member</h2>
                <p>{join1text}</p>
                <Button 
                        type="primary"
                        className="member"
                        size='large'
                        // onClick={() => memberClicked()}
                    >
                        <a href="https://forms.gle/MicPUyXwv6tUUBVFA">Join</a>
                    </Button>
            </div>
            {/* <div className='block1'>
                <img src = 'join2.jpg'></img>
                <h2>Intern</h2>
                <p>{join2text}</p>
                <Button 
                        type="primary"
                        className="member"
                        size='large'
                        onClick={() => internClicked()}
                    >Apply</Button>
            </div> */}
        </div>
    )
}

export {
    Join,
    JoinMobile
};