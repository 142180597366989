import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA3yu6zY5KDylou9YRY5E_mIGSQUFG-tL8",
    authDomain: "tsa-website-4c068.firebaseapp.com",
    projectId: "tsa-website-4c068",
    storageBucket: "tsa-website-4c068.appspot.com",
    messagingSenderId: "18696043925",
    appId: "1:18696043925:web:f8d460946e1465c0a04a9c",
    measurementId: "G-E928DVQD0R"
};

// Create a reference with an initial file path and name
export async function getEventURL(eventId) {
    const eventStorageRef = firebase.storage().ref("events");

    try {
        const downloadURL = await eventStorageRef.child(`${eventId}.jpg`).getDownloadURL();
        return downloadURL;
    } catch (error) {
        console.log(error);
        return "../../assets/image-not-found.png";
    }

}

export default firebase.initializeApp(firebaseConfig);
