import { GoogleSpreadsheet } from "google-spreadsheet";

const privatekey = require("./Keys/google_sheets_private_key.json");
const API_KEY = 'AIzaSyDchCT5oi2aVI_0_evu1Jz3Z7NYLzkhwCw';
const CLIENT_ID = '558563880520-a5uor2n1jjh3o4qtefka6ep3g554t2ne.apps.googleusercontent.com';
const SPREADSHEET_ID = '1-jNEMPWvNkVQ0F3wErGKk1rWYZ-zz1iPAmAwCKSgHcQ';
const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';
const internapp_ID = '1S1Rlf1K8Lrj1E9KnkKq5EBCayyfq_2ywuCtUXPWo19s';

export function addToMemberlist(values) {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: privatekey.client_email,
                private_key: privatekey.private_key,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[0];
            const result = await sheet.addRow(row);
            console.log(result);
        } catch (e) {
          console.error('Error: ', e);
        }
    };

    const name = values["first_name"] + " "+ values["last_name"];
      
    const newRow = { 
        Name: name, 
        Year: values["Graduation"], 
        Email: values["Email"], 
        Type: values['Type'],
        Confirmed: 'No' };
      
    appendSpreadsheet(newRow);
}


export function addToMailinglist(values) {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: privatekey.client_email,
                private_key: privatekey.private_key,
            });
            await doc.loadInfo();
            console.log(doc);
            const sheet = doc.sheetsById[396276949];
            const result = await sheet.addRow(row);
            console.log(result);
        } catch (e) {
          console.error('Error: ', e);
        }
    };
    const newRow = { 
        First: values["first_name"], 
        Last: values["last_name"],
        Email: values["Email"]
    };
      
    appendSpreadsheet(newRow);
}

export function addToInternInfo(values) {
    const doc = new GoogleSpreadsheet(internapp_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: privatekey.client_email,
                private_key: privatekey.private_key,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[0];
            const result = await sheet.addRow(row);
            console.log(result);
        } catch (e) {
          console.error('Error: ', e);
        }
    };
    const positions = values['first choice'] + ', ' + values['second choice'] + ', ' + values['third choice'];
    var interviewtimes = '';
    for (let interview of values['interview']) {
        interviewtimes += interview + ' '; 
    }
    const newRow = { 
        Name: values['name'], 
        Year: values["grad"], 
        Major: values["major"], 
        Email: values['email'],
        Positions: positions, 
        Interview: interviewtimes
    };
      
    appendSpreadsheet(newRow);
}