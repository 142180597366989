import React, {useEffect, useState} from 'react';
import './Officer.css'
import {Card, Col, Row, Modal} from "antd";
import firebase from "../firebase";
import {Intro, IntroMobile} from "../utils/Intro";
import {Link} from "react-router-dom";

function OfficerMobile() {
    const [officers, setOfficers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalOfficer, setModalOfficer] = useState({});

    useEffect(() => {
        (async () => {
            let allOfficers = []
            const officersRef = firebase.firestore().collection("officers");
            const snapshot = await officersRef.get();
            snapshot.forEach(doc => {
                allOfficers.push({id: doc.id, ...doc.data()});
            });
            setOfficers(allOfficers.sort((firstOfficer, secondOfficer) => firstOfficer.order - secondOfficer.order));
            console.log(allOfficers);
        })()
    }, [])


    function handleCardSelect(officer) {
        setModalVisible(true);
        setModalOfficer(officer);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }
    return (
        <div className="officers">
            <IntroMobile
                title={"Officers"}
                body={"TSA officers are from a variety of majors and are involved in other campus organizations! We’re driven, super friendly, and always down for a good time. Read more about our officers down below!"}
                imgURL={"tsafamfinal.jpg"}
            />

            <OfficerModal
                modalVisible={modalVisible}
                modalOfficer={modalOfficer}
                handleModalCancel={handleModalCancel}
                mobile={true}
            />

            <div className="officer-lists-mobile">
                <Row gutter={[24, 24]}>
                    {
                        officers.map((officer, index) => {
                            return (
                                <Col span={24}>
                                    <OfficerCard
                                        officer={officer}
                                        index={index}
                                        handleCardSelect={handleCardSelect}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <Link to="/join">
                <div className="apply-text-mobile">
                    <h1>Apply to be an intern</h1>
                    <img src={"arrow-right.png"} />
                </div>
            </Link>

        </div>
    )

}

function Officer() {
    const [officers, setOfficers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalOfficer, setModalOfficer] = useState({});

    useEffect(() => {
        (async () => {
            let allOfficers = []
            const officersRef = firebase.firestore().collection("officers");
            const snapshot = await officersRef.get();
            snapshot.forEach(doc => {
                allOfficers.push({id: doc.id, ...doc.data()});
            });
            setOfficers(allOfficers.sort((firstOfficer, secondOfficer) => firstOfficer.order - secondOfficer.order));
            console.log(allOfficers);
        })()
    }, [])


    function handleCardSelect(officer) {
        setModalVisible(true);
        setModalOfficer(officer);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }

    return (
        <div className="officers">
            <Intro
                title={"Officers"}
                body={"TSA officers are from a variety of majors and are involved in other campus organizations! We’re driven, super friendly, and always down for a good time. Read more about our officers down below!"}
                imgURL={"tsafamfinal.jpg"}
            />

            <OfficerModal
                modalVisible={modalVisible}
                modalOfficer={modalOfficer}
                handleModalCancel={handleModalCancel}
            />

            <div className="officer-lists">
                <Row gutter={[24, 32]}>
                    {
                        officers.map((officer, index) => {
                            return (
                                <Col span={8}>
                                    <OfficerCard
                                        officer={officer}
                                        index={index}
                                        handleCardSelect={handleCardSelect}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <Link to="/join">
                <div className="apply-text">
                    <h1>Apply to be an intern</h1>
                    <img src={"arrow-right.png"} />
                </div>
            </Link>

        </div>
    )
}
    
function OfficerCard({officer, index, handleCardSelect}) {
    return (
        <div
            className="officer-card"
            key={index}
            onClick={() => handleCardSelect(officer)}
        >
            <img src={officer.imageURL}/>
        </div>
    )
}

function OfficerModal({modalVisible, handleModalCancel, modalOfficer, mobile}) {
    if (mobile === true) {
        return (
            <Modal
                className="officer-modal"
                visible={modalVisible}
                width={"90%"}
                footer={null}
                onCancel={handleModalCancel}
                className = {"modal-mobile"}
            >
                <div className="modal-body-mobile">
                    <div className="modal-image">
                        <img src={modalOfficer.imageURL}/>
                    </div>
                    <div className="modal-content">
                        <h1>{modalOfficer.name}</h1>
                        <h2>{modalOfficer.position}</h2>
                        <p>{modalOfficer.description}</p>
                    </div>
                </div>
            </Modal>
        )
    } else {
        return (
            <Modal
                className="officer-modal"
                visible={modalVisible}
                width={"90%"}
                footer={null}
                onCancel={handleModalCancel}
            >
                <div className="modal-body">
                    <div className="modal-image">
                        <img src={modalOfficer.imageURL}/>
                    </div>
                    <div className="modal-content">
                        <h1>{modalOfficer.name}</h1>
                        <h2>{modalOfficer.position}</h2>
                        <p>{modalOfficer.description}</p>
                        <div className="grad-major">
                            <div className="graduation-detail">
                                <p><b>Graduation Year: </b>{modalOfficer.graduationYear}</p>
                            </div>
                            <div className="major-detail">
                                <p><b>Major: </b>{modalOfficer.major}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export {
    Officer,
    OfficerMobile
};