import React, {useState, useEffect} from 'react';
import './Header.css'
import './Header-mobile.css'
import logo from '../images/tsa_logo.png'
import {SocialIcon} from 'react-social-icons';
import Text from "antd/es/typography/Text";
import {Link} from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Button } from "antd";


function Hamburger() {
    const [visible, setVisible] = useState(false);
    return (
        <nav>
            <div className="NavBar-mobile">
                <a href="/" id='tsa-logo'>
                    <img src={logo} className="NavBarLogo-mobile"></img>
                </a>
                <Button
                    className="menu"
                    type="primary"
                    icon={<MenuOutlined className='buttonham'/>}
                    onClick={() => setVisible(true)}
                    size='large'
                />
                <Drawer
                    placement='top'
                    onClick={() => setVisible(false)}
                    onClose={() => setVisible(false)}
                    visible={visible}
                > 
                    <ul className="nav">
                        <li className="NavBar-Options">
                            <Link to="/events">
                                Events
                            </Link>
                        </li>
                        <li className="NavBar-Options">
                            <Link to="/officers">
                                Officers
                            </Link>
                        </li>
                        <li className="NavBar-Options">
                            <Link to="/tsai">
                                TSAi
                            </Link>
                        </li>
                        <li className="NavBar-Options">
                            <Link to="/resources">
                                Resources
                            </Link>
                        </li>
                        <li className="NavBar-Options">
                        <Link to="/sponsor">
                                Sponsors
                        </Link>
                        </li>
                        <li className="NavBar-Options">
                            <Link to="/join">
                                Join Us
                            </Link>
                        </li>
                        <li className="NavBar-Options">
                            <Link to="/contact">
                                    Contact
                            </Link>
                        </li>
                        {/* <li className="NavBar-Options">
                            <Link to="/internapp">
                                    Intern App
                            </Link>
                        </li> */}
                        
                        
                    </ul>
                </Drawer>
                
            </div>
        </nav>
    )
}

function NavBar() {
    return (
        <nav>
            <div className="NavBar">
                <a href="/" id='tsa-logo'>
                    <img src={logo} className="NavBarLogo"></img>
                </a>
                <ul id="nav">
                    {/* <li className="NavBar-Options">
                        <Link to="/internapp">
                            Intern App
                        </Link>
                    </li> */}
                    <li className="NavBar-Options">
                        <Link to="/contact">
                                Contact
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/sponsor">
                                Sponsors
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/join">
                                Join Us
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/resources">
                            Resources
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/tsai">
                            TSAi
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/officers">
                            Officers
                        </Link>
                    </li>
                    <li className="NavBar-Options">
                        <Link to="/events">
                            Events
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export {
    Hamburger,
    NavBar
}