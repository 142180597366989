import React from 'react';
import './Footer.css'
import './Footer-mobile.css'
import {SocialIcon} from 'react-social-icons';
import {Link} from "react-router-dom";


function MobileFooter() {
    return (
        <div className='Footer-mobile'>
            <ul className="foot">
                <li className='links'>
                    <Link to="/events">
                        Events
                    </Link>
                </li>
                <li className='links'> 
                    <Link to="/officers">
                        Officers
                    </Link>
                </li>
                <li className="links">
                    <Link to="/tsai">
                        TSAi
                    </Link>
                </li>
                <li className='links'>
                    <Link to="/resources">
                        Resources
                    </Link>
                </li>
                <li className='links'>
                    <Link to="/join">
                        Join Us
                    </Link>
                </li>
                <li className='links'>
                    <Link to="/sponsor">
                        Sponsors
                    </Link>
                </li>
                <li className='links'> 
                    <Link to="/contact">
                        Contact
                    </Link>
                </li>
            </ul>
            <div className='Contacts'>
                <div className='icons'>
                    <a href="https://www.facebook.com/ucberkeleytsa" target="_blank" rel="noopener noreferrer">
                        <img src='./whitefacebook.png' className='icon'></img>
                    </a>
                    <a href="https://www.instagram.com/berkeleytsa" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteinsta.png' className='icon'></img>
                    </a>
                      <a href="https://www.youtube.com/user/TSABERKELEY" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteyoutube.png' className='icon'></img>
                    </a>
                    <a href="mailto:tsa.berkeley@gmail.com" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteemail.png' className='icon'></img>
                    </a>
                </div>
                <a href="https://www.ocf.berkeley.edu" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.ocf.berkeley.edu/hosting-logos/ocf-hosted-penguin.svg" className="ocf-logo"/>
                </a>
            </div>
        </div>
    )
}

function Footer() {
    return (
        <div className='Footer'>
            <ul className="foot">
                <li>
                    <Link to="/events">
                        Events
                    </Link>
                </li>
                <li>
                    <Link to="/officers">
                        Officers
                    </Link>
                </li>
                <li>
                    <Link to="/tsai">
                        TSAi
                    </Link>
                </li>
                <li>
                    <Link to="/resources">
                        Resources
                    </Link>
                </li>
                <li><Link to="/join">Join Us</Link></li>
                <li>
                    <Link to="/sponsor">
                        Sponsors
                    </Link>
                </li>
                <li>
                    <Link to="/contact">
                        Contact
                    </Link>
                </li>
            </ul>
            <div className='Contacts'>
                <h2>Contact</h2>
                <div className='icons'>
                    <a href="https://www.facebook.com/ucberkeleytsa" target="_blank" rel="noopener noreferrer">
                        <img src='./whitefacebook.png' className='icon'></img>
                    </a>
                    <a href="https://www.instagram.com/berkeleytsa" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteinsta.png' className='icon'></img>
                    </a>
                      <a href="https://www.youtube.com/user/TSABERKELEY" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteyoutube.png' className='icon'></img>
                    </a>
                    <a href="mailto:tsa.berkeley@gmail.com" target="_blank" rel="noopener noreferrer">
                        <img src='./whiteemail.png' className='icon'></img>
                    </a>
                </div>
                <a href="https://www.ocf.berkeley.edu" className="ocf-logo" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.ocf.berkeley.edu/hosting-logos/ocf-hosted-penguin.svg"/>
                </a>
            </div>
        </div>
    )
}

export {
    Footer,
    MobileFooter
}